import React, { useState, useEffect } from "react";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import { Project } from "../Project";

const Summary = ({ nextStep, prevStep, values }) => {
	//destructuring the object from values

	const {
		projectName,
		projectDescription,
		projectType,
		dwVersion,
		swiftVersion,
		server,
		staging
	} = values;

	const [testServer, setTestServer] = useState();
	const [testDomain, setTestDomain] = useState("");
	const [mailAdresses, setMailAdresses] = useState();

	const project = {
		name: projectName,
		description: projectDescription,
		projectType: projectType,
		dwVersion: dwVersion,
		swiftVersion: swiftVersion,
		server: server,
		staging: staging,
		testServerUrl: testServer,
		mailAdresses: mailAdresses
	};

	const projectFunc = () => {
		if (projectType === "custom") {
			project.projectType = "Custom Project with empty repository";
		} else if (projectType === "DW10") {
			project.projectType = "Custom DW10 Project with empty repository";
		} else {
			project.projectType = projectType;
		}
		if (projectType === "dw10") {
			project.projectType = "dw10";
		}

		return project.projectType;
	};

	const joinTestServer = (e) => {
		var joinedName = project.name.replace(/ /g, "-");

		if (testDomain !== "") {
			joinedName = e;
		}
		if (projectType === "dw10") {
			joinedName = joinedName + ".test002.webtester.dk";
		} else {
			joinedName = joinedName + ".test001.webtester.dk";
		}
		joinedName = joinedName.toLowerCase();
		setTestDomain(joinedName);
		if (projectType === "dw10") {
			joinedName = joinedName + ".test002.webtester.dk";
		} else {
			joinedName = joinedName + ".test001.webtester.dk";
		}
		setTestServer(joinedName);

		values.testServerUrl = joinedName;
	};

	const getMailAdresses = (e) => {
		setMailAdresses(e);
	};

	useEffect(() => {
		joinTestServer();
	}, []);

	return (
		<>
			<Card>
				<Card.Body>
					<Container>
						<Row>
							<Col>
								<h3 className="headline">Project name</h3>
							</Col>
							<Col>
								<h3 className="headline">Project description</h3>
							</Col>
						</Row>
						<Row>
							<Col>{projectName}</Col>
							<Col>{projectDescription}</Col>
						</Row>
						<Row style={{ height: 30 }}></Row>
						<Row>
							<Col className={`${projectType !== "standard" ? "d-none" : ""}`}>
								<Row>
									<Col>
										<h3 className="headline">Dynamicweb</h3>
									</Col>
								</Row>
								<Row>
									<Col>{dwVersion}</Col>
								</Row>
							</Col>
							<Col className={`${projectType === "standard" ? "d-none" : ""}`}>
								<Row>
									<Col>
										<h3 className="headline">Project</h3>
									</Col>
								</Row>
								<Row>
									<Col>{projectFunc()}</Col>
								</Row>
							</Col>

							<Col className={`${projectType !== "standard" ? "d-none" : ""}`}>
								<Row>
									<Col>
										<h3 className="headline">Swift</h3>
									</Col>
								</Row>
								<Row>
									<Col>{swiftVersion}</Col>
								</Row>
							</Col>
						</Row>
						<Row style={{ height: 30 }}></Row>
						<Row>
							<Col>
								<Row>
									<Col>
										<h3 className="headline">Test domain</h3>
									</Col>
								</Row>
								<Row>
									<Col>{server}</Col>
								</Row>
								<Row>
									<Col className="test-col">
										<div className="js-testserver">
											http://
											<input
												value={testDomain}
												onChange={(e) => joinTestServer(e.target.value)}
											/>
											{`${projectType !== "dw10" ? ".test001.webtester.dk" : ".test002.webtester.dk"}`}
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row style={{ height: 30 }}></Row>
						<Row>
							<Col>
								<h3 className="headline">Send info mail to</h3>
								<p>
									Beside your own, which mail should we send the information to?
								</p>
							</Col>
						</Row>
						<Row>
							<Col>
								<input
									className="js-input-mail"
									onChange={(e) => getMailAdresses(e.target.value)}
								/>
							</Col>
						</Row>
					</Container>
					<div className="buttonContainer">
						<Button variant="blank" onClick={prevStep}>
							Previous
						</Button>

						<Project project={project} />
						<Button
							className="js-final-btn"
							style={{ display: "none" }}
							onClick={nextStep}
						/>
					</div>
				</Card.Body>
			</Card>
		</>
	);
};

export default Summary;
