import React from "react";
//import { useIsAuthenticated } from "@azure/msal-react";

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const PageLayout = (props) => {
	//const isAuthenticated = useIsAuthenticated();

	return <>{props.children}</>;
};
