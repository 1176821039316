import axios from "axios";

export async function createDatabase(accessToken, project, server) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json"
	};

	const toCamelCase = (str) => {
		const arr = str.split(" ");
		for (var i = 0; i < arr.length; i++) {
			arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
		}
		const str2 = arr.join(" ");
		return str2.replace(/ /g, "");
	};

	return await axios
		.post(
			`${process.env.REACT_APP_WEB_API}/database/create?database=Dynamicweb_${toCamelCase(project.name)}&server=${server}`,
			{},
			{ headers: headers }
		)
		.then(() => {
			installSwift(accessToken, project, server);
		});
}

export async function installSwift(accessToken, project, server) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json"
	};

	const toCamelCase = (str) => {
		const arr = str.split(" ");
		for (var i = 0; i < arr.length; i++) {
			arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
		}
		const str2 = arr.join(" ");
		return str2.replace(/ /g, "");
	};
	if (project.projectType === "dw10") {
		return axios.post(
			`${process.env.REACT_APP_WEB_API}/database/install/swift?database=Dynamicweb_${toCamelCase(project.name)}&server=${server}&version=dw10`,
			project.swiftVersion,
			{},
			{ headers: headers }
		);
	} else {
		return await axios.post(
			`${process.env.REACT_APP_WEB_API}/database/install/swift?database=Dynamicweb_${toCamelCase(project.name)}&server=${server}&version=${project.swiftVersion}`,
			project.swiftVersion,
			{},
			{ headers: headers }
		);
	}
}
