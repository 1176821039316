import axios from "axios";

export async function sendMail(project, account) {
	//console.log(accessToken);
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json"
	};
	return await axios
		.post(
			`${process.env.REACT_APP_WEB_API}/mail/send`,
			{
				projectName: project.name,
				websiteName: project.testServerUrl,
				dwVersion: project.dwVersion,
				swiftVersion: project.swiftVersion,
				toEmail: account.username + "," + project.mailAdresses
			},
			{ headers: headers }
		)
		.then(() => {});
}
