import axios from "axios";

export async function importProject(accessToken, project, repoId, projectId) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};

	return await axios.post(
		`${process.env.REACT_APP_WEB_API}/git/import/${project.name}/${repoId}/${projectId}/${project.projectType}`,
		{},
		{ headers: headers }
	);
}
