import { useMsal } from "@azure/msal-react";
import { useState } from "react";
import { loginRequest } from "../authConfig";
import { Button } from "react-bootstrap";

import { createProject } from "./Requests/Projects";

export const Project = (project) => {
	const { instance, accounts } = useMsal();
	//const name = accounts[0] && accounts[0].name;

	//const [post, setPost] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	function CreateProject() {
		window.addEventListener("beforeunload", (ev) => {
			ev.preventDefault();
			return (ev.returnValue = "We are not finish creating everything!");
		});

		setIsLoading(true);

		const request = {
			...loginRequest,
			account: accounts[0]
		};

		// Silently acquires an access token which is then attached to a request for Microsoft Graph data
		instance
			.acquireTokenSilent(request)
			.then(async (msal) => {
				// Create project
				createProject(msal.accessToken, project, msal.account);
			})
			.catch((e) => {
				console.log(e);
			});
	}

	return (
		<>
			<Button onClick={CreateProject}>Create Project</Button>
			<div className={`loading-overlay ${isLoading ? "active" : ""}`}>
				<div className="loading-container">
					<ul className={`loading-list ${isLoading ? "" : "d-none"}`}>
						<li className="loading project">
							<div className="loading-icon"></div>
							<span>Creating your project</span>
						</li>
						<li className="loading repo d-none">
							<div className="loading-icon"></div>
							<span>Cloning repository</span>
						</li>
						<li className="loading build d-none">
							<div className="loading-icon"></div>
							<span>Creating build pipeline</span>
						</li>
						<li className="loading deployment d-none">
							<div className="loading-icon"></div>
							<span>Adding website to deployment pool</span>
						</li>
						<li className="loading release d-none">
							<div className="loading-icon"></div>
							<span>Creating release pipeline</span>
						</li>
						<li className="loading branch d-none">
							<div className="loading-icon"></div>
							<span>Creating release branch</span>
						</li>
						<li className="loading pull-request d-none">
							<div className="loading-icon"></div>
							<span>Creating pull request to start release</span>
						</li>
						<li className="loading create-database d-none">
							<div className="loading-icon"></div>
							<span>Creating database for test site</span>
						</li>
					</ul>
					<label className={`${isLoading ? "" : "d-none"}`}>
						<span className="sr-only">Loading progress</span>
						<progress
							className="progress-bar-custom"
							role="progressbar"
							aria-describedby="loading-zone"
							tabIndex={-1}
						>
							unknown
						</progress>
					</label>
				</div>
			</div>
		</>
	);
};
