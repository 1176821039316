import "./App.css";
import { PageLayout } from "./components/PageLayout";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Steps } from "./components/Steps/Steps";
import { Container, Row, Col } from "react-bootstrap";
import { SignInButton } from "./components/SignInButton";

function App() {
	return (
		<PageLayout>
			<AuthenticatedTemplate>
				<Steps />
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<div className="App">
					<Container>
						<Row>
							<Col>
								<h1>Co3 Provisioning Tool</h1>
							</Col>
						</Row>
						<Row className="login-container">
							<Col>
								<SignInButton />
							</Col>
						</Row>
					</Container>
				</div>
			</UnauthenticatedTemplate>
		</PageLayout>
	);
}

export default App;
