import axios from "axios";

export async function createBuild(accessToken, project, repoId) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};

	return await axios.post(
		`${process.env.REACT_APP_WEB_API}/build/create/${project.name}/${repoId}`,
		{},
		{ headers: headers }
	);
}

export async function runBuild(accessToken, project) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};

	return await axios.post(
		`${process.env.REACT_APP_WEB_API}/build/run`,
		{
			projectName: project.name
		},
		{ headers: headers }
	);
}
