import axios from "axios";

export async function createRelease(
	accessToken,
	project,
	deploymentGroupId,
	projectId,
	buildId,
	accountEmail,
	projectType,
	testServer
) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};
	return await axios.post(
		`${process.env.REACT_APP_WEB_API}/deployment/release/create/${deploymentGroupId}/${projectType}`,
		{
			projectName: project.name,
			testServer: testServer,
			testServerUrl: project.testServerUrl,
			projectId: projectId,
			buildId: buildId,
			userMail: accountEmail.username
		},
		{ headers: headers }
	);
}
export async function createTempReleaseInBoilerplateProject(
	accessToken,
	project,
	deploymentGroupId,
	projectId,
	buildId,
	account
) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};
	return await axios.post(
		`${process.env.REACT_APP_WEB_API}/deployment/release/create/${deploymentGroupId}/dw10BoilerplateRelease`,
		{
			projectName: project.name,
			testServer: "azure-test001",
			testServerUrl: project.testServerUrl,
			projectId: projectId,
			buildId: buildId,
			userMail: account.username
		},
		{ headers: headers }
	);
}
