export const msalConfig = {
	auth: {
		clientId: `${process.env.REACT_APP_AZURE_APP_CLIENT_ID}`,
		authority: `${process.env.REACT_APP_AZURE_APP_AUTHORITY}`,
		redirectUri: `${process.env.REACT_APP_AZURE_APP_REDIRECT_URL}`,
		postLogoutRedirectUri: `${process.env.REACT_APP_AZURE_APP_REDIRECT_URL}`
	},
	cache: {
		cacheLocation: "sessionStorage", // This configures where your cache will be stored
		storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
	}
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
	scopes: ["499b84ac-1321-427f-aa17-267ca6975798/user_impersonation"]
};
