import axios from "axios";

export async function appendDeploymentPool(accessToken, project, testServer) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};

	return axios.post(
		`${process.env.REACT_APP_WEB_API}/deployment/append/${project.name}/${testServer}`,
		{},
		{ headers: headers }
	);
}

export async function getDeploymentGroup(accessToken, projectName) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};

	return axios.get(`${process.env.REACT_APP_WEB_API}/deployment/group/${projectName}`, {
		headers: headers
	});
}

export async function getAgentGroup(accessToken, projectName) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};

	return axios.get(`${process.env.REACT_APP_WEB_API}/agent/group/${projectName}`, {
		headers: headers
	});
}
