import React, { useState } from "react";
import { Form, Card, Button, Row, Col } from "react-bootstrap";
import validator from "validator";
import DWLogo from "../../resources/dwlogo.svg";
import Computer from "../../resources/computer.svg";

// creating functional component ans getting props from app.js and destucturing them
const StepTwo = ({ nextStep, prevStep, values, versions }) => {
	//creating error state for validation
	const [error, setError] = useState(false);
	const [projectType, setProjectType] = useState();

	// after form submit validating the form data using validator
	const submitFormData = (e) => {
		e.preventDefault();
		if (projectType === "custom" || projectType === "dw10") {
			nextStep();
		} else if (projectType === "DW10") {
			nextStep();
		} else if (projectType === "standard" && !validator.isEmpty(values.dwVersion)) {
			nextStep();
		} else {
			setError(true);
		}
	};

	const handleChangeProjectType = (projectType, dwversion, swiftversion) => {
		values.dwVersion = dwversion;
		values.swiftVersion = swiftversion;
		values.projectType = projectType;

		setProjectType(projectType);
	};

	return (
		<>
			<Card>
				<Card.Body>
					<h3 className="headline">Project</h3>
					<Form className="form" onSubmit={submitFormData}>
						<Form.Group className="mb-3 custom-radios">
							<Form.Label>What kind of project do you need</Form.Label>
							{versions.map((version) => (
								<label
									key="{version.swiftVersion}"
									className={`form-wrapper ${
										values.swiftVersion === version.swift ? "active" : ""
									}`}
								>
									<Row className="align-items-center">
										<Col md={1}>
											<img className="radio-img" src={DWLogo} alt="Dw logo" />{" "}
										</Col>
										<Col>
											<input
												name="projectType"
												type="radio"
												className="form-check-input"
												value="standard"
												onClick={() =>
													handleChangeProjectType(
														"standard",
														version.dynamicweb,
														version.swift
													)
												}
											></input>
											<span className="form-label">{`Dynamicweb ${version.dynamicweb}`}</span>

											<div className="radio-text">
												{`This will create a Dynamicweb website with a Swift ${version.swift}
												template`}
											</div>
										</Col>
										<Col md={1}>
											{/* <div className="dw-version">
												<span>Dynamicweb {version.dynamicweb}</span>
												<span>Swift {version.swift}</span>
											</div> */}
											<div className="checked"></div>
										</Col>
									</Row>
								</label>
							))}
							<label
								key="custom"
								className={`form-wrapper ${projectType === "custom" ? "active" : ""}`}
							>
								<Row className="align-items-center">
									<Col md={1}>
										<img
											className="radio-img"
											src={Computer}
											alt="computer icon"
										/>{" "}
									</Col>
									<Col>
										<input
											name="projectType"
											type="radio"
											className="form-check-input"
											value="custom"
											onClick={() =>
												handleChangeProjectType("custom", null, null)
											}
										></input>
										<span className="form-label">Custom project</span>
										<div className="radio-text">
											This creates an empty project, where you can upload your
											own code
										</div>
									</Col>
									<Col md={1}>
										<div className="checked"></div>
									</Col>
								</Row>
							</label>
							<label
								key="dw10"
								className={`form-wrapper ${projectType === "dw10" ? "active" : ""}`}
							>
								<Row className="align-items-center">
									<Col md={1}>
										<img
											className="radio-img"
											src={Computer}
											alt="computer icon"
										/>{" "}
									</Col>
									<Col>
										<input
											name="projectType"
											type="radio"
											className="form-check-input"
											value="dw10"
											onClick={() =>
												handleChangeProjectType("dw10", null, null)
											}
										></input>
										<span className="form-label">Dynamicweb 10</span>
										<div className="radio-text">
											This will create a Dynmicweb 10 website with the latest
											swift template
										</div>
									</Col>
									<Col md={1}>
										<div className="checked"></div>
									</Col>
								</Row>
							</label>
							{error ? (
								<Form.Text style={{ color: "red" }}>
									This is a required field
								</Form.Text>
							) : (
								""
							)}
						</Form.Group>

						<div className="buttonContainer">
							<Button variant="blank" onClick={prevStep}>
								Previous
							</Button>

							<Button variant="primary" type="submit">
								Continue
							</Button>
						</div>
					</Form>
				</Card.Body>
			</Card>
		</>
	);
};

export default StepTwo;
