import { Card, Container } from "react-bootstrap";

const Final = (values) => {
	//destructuring the object from values
	return (
		<>
			<Card>
				<Card.Body>
					<Container>
						<h2>Sådan!</h2> <br />
						Du har oprettet et projekt.
						<br />
						<br />
						{values.values.swiftVersion != null ? (
							<div>
								Vores automatiseringsværktøj går nu i gang med at oprette et site
								til dig, på vores testserver.
								<br />
								<br />
								Der kan godt gå 15-20 minutter før alt er oprettet. Så snup dig en
								kop kaffe og en gåtur, og når du er tilbage kan du tjekke om dit nye
								site er oprettet.
								<br />
								<br />
								<b>Test site:</b>{" "}
								<a
									target="_blank"
									rel="noreferrer"
									href={`http://${values.values.testServerUrl}`}
								>{`${values.values.testServerUrl}`}</a>
								<br />
							</div>
						) : (
							""
						)}
						<b>DevOps:</b>{" "}
						<a
							target="_blank"
							rel="noreferrer"
							href={`https://dev.azure.com/Co3AS/${values.values.projectName}`}
						>{`https://dev.azure.com/Co3AS/${values.values.projectName}`}</a>
					</Container>
				</Card.Body>
			</Card>
		</>
	);
};

export default Final;
