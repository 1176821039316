import axios from "axios";

export async function getRepositoryId(accessToken, projectName) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};

	return axios.get(`${process.env.REACT_APP_WEB_API}/repository/${projectName}`, {
		headers: headers
	});
}

export async function getRepository(accessToken, project, repoId) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};

	return await axios.get(
		`${process.env.REACT_APP_WEB_API}/repository/${project.name}/${repoId}`,
		{
			headers: headers
		}
	);
}

export async function createBranch(accessToken, repoId, project) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};

	return await axios.post(
		`${process.env.REACT_APP_WEB_API}/repository/branch/create/`,
		{
			repoId: repoId,
			fromBranch: "main",
			toBranch: "release",
			projectName: project.name
		},
		{ headers: headers }
	);
}
export async function createBranchForProject(accessToken, repoId, project) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};

	return await axios.post(
		`${process.env.REACT_APP_WEB_API}/repository/branch/create/`,
		{
			repoId: repoId,
			fromBranch: "main",
			toBranch: project.name,
			projectName: project.name
		},
		{ headers: headers }
	);
}

export async function createPullRequest(accessToken, repoId, userId) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};

	return await axios.post(
		`${process.env.REACT_APP_WEB_API}/repository/pullrequest/create`,
		{
			repoId: repoId,
			userId: userId,
			fromBranch: "main",
			toBranch: "release"
		},
		{ headers: headers }
	);
}

export async function createCommit(accessToken, project, repoId, objectId, userId, testServer) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};
	if (project.projectType === "dw10") {
		return await axios
			.post(
				`${process.env.REACT_APP_WEB_API}/repository/commit/${testServer}/${project.projectType}`,
				{
					objectId: objectId,
					comment: "created custom globalsetting",
					pathToFile: "Files/wwwroot/Files/GlobalSettings.Database.config",
					repoId: repoId,
					projectName: project.name
				},
				{ headers: headers }
			)
			.then(() => {
				getCommitId(accessToken, project, repoId, userId);
			});
	} else {
		return await axios
			.post(
				`${process.env.REACT_APP_WEB_API}/repository/commit/${testServer}/${project.projectType}`,
				{
					objectId: objectId,
					comment: "created custom globalsetting",
					pathToFile: "/Files/Files/GlobalSettings.Database.config",
					repoId: repoId,
					projectName: project.name
				},
				{ headers: headers }
			)
			.then(() => {
				return getCommitId(accessToken, project, repoId, userId);
			});
	}
}

export async function renameFile(accessToken, project, repoId, objectId, userId) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};

	const toCamelCase = (str) => {
		const arr = str.split(" ");
		for (var i = 0; i < arr.length; i++) {
			arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
		}
		const str2 = arr.join(" ");
		return str2.replace(/ /g, "");
	};

	return await axios
		.post(
			`${process.env.REACT_APP_WEB_API}/repository/rename`,
			{
				objectId: objectId,
				comment: "renamed source file",
				pathToFile: "/" + toCamelCase(project.name) + ".sln",
				repoId: repoId,
				projectName: project.name
			},
			{ headers: headers }
		)
		.then(() => {
			createPullRequest(accessToken, repoId, userId, project);
		});
}

export async function getCommitId(accessToken, project, repoId, userId) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};

	return await axios
		.get(`${process.env.REACT_APP_WEB_API}/repository/${project.name}/${repoId}`, {
			headers: headers
		})
		.then((response) => {
			renameFile(accessToken, project, repoId, response.data, userId);
		});
}
