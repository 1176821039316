import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import Summary from "./Summary";
import Final from "./Final";
import "./Steps.scss";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/Button";

export const Steps = () => {
	const [step, setstep] = useState(1);

	const [formData, setFormData] = useState({
		projectName: "",
		projectDescription: "",
		projectType: "",
		dwVersion: "",
		dwUrl: "",
		server: "",
		testServerUrl: "",
		staging: false
	});

	// function for going to next step by increasing step state by 1
	const nextStep = () => {
		setstep(step + 1);
	};

	// function for going to previous step by decreasing step state by 1
	const prevStep = () => {
		setstep(step - 1);
	};

	// handling form input data by taking onchange value and updating our previous form data state
	const handleInputData = (input) => (e) => {
		// input value from the form
		const { value } = e.target;

		//updating form data state taking previous state and then adding new value to create new object
		setFormData((prevState) => ({
			...prevState,
			[input]: value
		}));
	};

	const [versions, setVersions] = useState([]);
	const fetchData = () => {
		axios.get(`${process.env.REACT_APP_WEB_API}/versions`).then((res) => {
			setVersions(res.data.dynamicweb);
		});
	};

	useEffect(() => {
		fetchData();
	}, []);

	const RenderSteps = (step) => {
		switch (step) {
			case 1:
				return (
					<StepOne
						nextStep={nextStep}
						handleFormData={handleInputData}
						values={formData}
					/>
				);
			case 2:
				return (
					<StepTwo
						nextStep={nextStep}
						prevStep={prevStep}
						handleFormData={handleInputData}
						values={formData}
						versions={versions}
					/>
				);
			case 3:
				return <Summary values={formData} prevStep={prevStep} nextStep={nextStep} />;
			case 4:
				return <Final values={formData} prevStep={prevStep} />;
			default:
				return;
		}
	};

	const { instance } = useMsal();
	const handleLogout = (instance) => {
		instance.logout().catch((e) => {
			console.error(e);
		});
	};

	return (
		<div className="App">
			<Container>
				<Row>
					<Col>
						<h1>Co3 Provisioning Tool</h1>
						<p>Follow the steps to create your new project</p>
					</Col>
					<Col className="justify-content-end d-flex align-items-center">
						<Button
							variant="secondary"
							className="ml-auto"
							onClick={() => handleLogout(instance)}
						>
							Sign out
						</Button>
					</Col>
				</Row>
				<Row style={{ marginTop: 30 }} className="border-top">
					<Col md={{ span: 3 }} className="border-right">
						<section className="step-indicator">
							<Row className={`step step1 ${step >= 1 ? "active" : ""}`}>
								<Col md={{ span: 8 }}>
									<div className="step-header">Project Info</div>
									<div className="step-text">Name and description</div>
								</Col>
								<Col md={{ span: 3 }}>
									<div className="step-icon">
										<div
											className={`icon-image info ${
												step >= 1 ? "active" : ""
											}`}
										></div>
									</div>
								</Col>
								<Col md={{ span: 1 }}>
									<div className={`circle ${step >= 1 ? "active" : ""}`}></div>
								</Col>
							</Row>
							<Row className="indicator-wrapper">
								<Col md={{ span: 8 }}></Col>
								<Col className="line-wrapper" md={{ span: 3 }}>
									<div className="indicator-line"></div>
									<div
										className={`thick-line ${step >= 2 ? "active" : ""}`}
									></div>
								</Col>
								<Col md={{ span: 1 }}></Col>
							</Row>

							<Row className={`step step2 ${step >= 2 ? "active" : ""}`}>
								<Col md={{ span: 8 }}>
									<div className="step-header">Project type</div>
									<div className="step-text">Swift or custom</div>
								</Col>
								<Col md={{ span: 3 }}>
									<div className="step-icon">
										<div
											className={`icon-image code ${
												step >= 2 ? "active" : ""
											}`}
										></div>
									</div>
								</Col>
								<Col md={{ span: 1 }}>
									<div className={`circle ${step >= 2 ? "active" : ""}`}></div>
								</Col>
							</Row>

							<Row className="indicator-wrapper">
								<Col md={{ span: 8 }}></Col>
								<Col className="line-wrapper" md={{ span: 3 }}>
									<div className="indicator-line"></div>
									<div
										className={`thick-line ${step >= 3 ? "active" : ""}`}
									></div>
								</Col>
								<Col md={{ span: 1 }}></Col>
							</Row>

							<Row className={`step step4 ${step >= 3 ? "active" : ""}`}>
								<Col md={{ span: 8 }}>
									<div className="step-header">Summary</div>
									<div className="step-text">Check your data</div>
								</Col>
								<Col md={{ span: 3 }}>
									<div className="step-icon">
										<div
											className={`icon-image rocket ${
												step >= 3 ? "active" : ""
											}`}
										></div>
									</div>
								</Col>
								<Col md={{ span: 1 }}>
									<div className={`circle ${step >= 3 ? "active" : ""}`}></div>
								</Col>
							</Row>

							<Row className="indicator-wrapper">
								<Col md={{ span: 8 }}></Col>
								<Col className="line-wrapper" md={{ span: 3 }}>
									<div className="indicator-line"></div>
									<div
										className={`thick-line ${step >= 4 ? "active" : ""}`}
									></div>
								</Col>
								<Col md={{ span: 1 }}></Col>
							</Row>

							<Row className={`step step4 ${step >= 4 ? "active" : ""}`}>
								<Col md={{ span: 8 }}>
									<div className="step-header">Final</div>
									<div className="step-text">Project created</div>
								</Col>
								<Col md={{ span: 3 }}>
									<div className="step-icon">
										<div
											className={`icon-image email ${
												step >= 4 ? "active" : ""
											}`}
										></div>
									</div>
								</Col>
								<Col md={{ span: 1 }}>
									<div className={`circle ${step >= 4 ? "active" : ""}`}></div>
								</Col>
							</Row>
						</section>
					</Col>
					<Col style={{ marginTop: 30, paddingLeft: 30 }} md={{ span: 9 }}>
						{RenderSteps(step)}
					</Col>
				</Row>
			</Container>
		</div>
	);
};
